var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('section',{attrs:{"id":"content"}},[_c('div',{class:{ stickyHeader: _vm.$vuetify.breakpoint.lgAndUp, stickyHeaderSmall: !_vm.$vuetify.breakpoint.lgAndUp }},[_c('v-row',{staticStyle:{"padding":"15px"}},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"depressed":"","small":"","to":{path: ("/agpa/archives/" + _vm.year) }}},on),[_c('v-icon',{attrs:{"left":""}},[_vm._v(" fas fa-chevron-left ")]),(_vm.$vuetify.breakpoint.mdAndUp)?_c('span',[_vm._v("Retour")]):_vm._e()],1)]}}])},[_c('span',[_vm._v("Retour au sommaire de l'édition")])]),_c('v-spacer'),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"icon":"","small":"","disabled":_vm.isLoading},on:{"click":function($event){return _vm.gotoNextYear(-1)}}},on),[_c('v-icon',[_vm._v("fa-chevron-left")])],1)]}}])},[_c('span',[_vm._v("Edition précédente")])]),_c('span',{staticClass:"grey--text"},[_vm._v(" "+_vm._s(_vm.year)+" ")]),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"icon":"","small":"","disabled":_vm.isLoading},on:{"click":function($event){return _vm.gotoNextYear(1)}}},on),[_c('v-icon',[_vm._v("fa-chevron-right")])],1)]}}])},[_c('span',[_vm._v("Edition suivante")])]),_c('v-spacer'),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"icon":"","small":"","disabled":_vm.isLoading},on:{"click":function($event){return _vm.gotoNextCat(-1)}}},on),[_c('v-icon',[_vm._v("fas fa-chevron-left")])],1)]}}])},[_c('span',[_vm._v("Catégorie précédente")])]),_c('span',{staticClass:"grey--text"},[_vm._v(" "+_vm._s(_vm.agpaMeta ? _vm.agpaMeta.categories[_vm.category].title : '...')+" ")]),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"icon":"","small":"","disabled":_vm.isLoading},on:{"click":function($event){return _vm.gotoNextCat(1)}}},on),[_c('v-icon',[_vm._v("fas fa-chevron-right")])],1)]}}])},[_c('span',[_vm._v("Catégorie suivante")])]),_c('v-spacer')],1),(_vm.isLoading)?_c('v-progress-linear',{staticStyle:{"position":"absolute","bottom":"-5px","left":"0","right":"0","height":"5px"},attrs:{"color":"accent","indeterminate":""}}):_vm._e()],1),(_vm.current)?_c('div',[(_vm.current)?_c('v-container',{attrs:{"fluid":""}},[_c('v-layout',{attrs:{"row":"","wrap":""}},_vm._l((_vm.photosGalery),function(photo,index){return _c('v-flex',{key:photo.id,staticStyle:{"min-width":"250px","width":"15%","margin":"15px"}},[_c('div',[_c('div',{staticStyle:{"width":"250px","height":"250px","margin":"auto"}},[_c('div',{staticStyle:{"width":"250px","height":"250px","display":"table-cell","text-align":"center","vertical-align":"middle"}},[_c('img',{staticClass:"thumb",attrs:{"src":photo.thumb},on:{"click":function($event){return _vm.photosGalleryDisplay(index)}}})])]),_c('div',{}),_c('v-card',{staticClass:"card shiny",class:{
                gold: photo.rank == 1,
                sylver: photo.rank === 2,
                bronze: photo.rank === 3 },staticStyle:{"margin-bottom":"50px"}},[_c('div',{staticClass:"thumb-title"},[_vm._v(" "+_vm._s(photo.title)+" ")]),_c('div',{staticStyle:{"position":"absolute","bottom":"5px","left":"5px","right":"5px","opacity":".5"}},[_vm._v(" "+_vm._s(photo.username)+" ")])])],1)])}),1)],1):_vm._e()],1):_vm._e()])}
var staticRenderFns = []

export { render, staticRenderFns }